import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

import { GoogleLogin } from "@react-oauth/google";
import { Button, Card, message, Modal } from "antd";

const RulesText = () => (
  <>
    <p>
      Welcome to the Man Cave Fantasy Football Playoffs League 2024. See the
      instructions to enter the League, Site Description with Live Updates,
      League Rules & Scoring/Points System below.
    </p>
    <h1>Important Instructions to follow:</h1>
    <p>
      You must have a Gmail email address to log into the Man Cave Fantasy
      Football Playoffs. This site is built on a google platform & it’s a must
      to have a google email to be able to log into the contest to enter
      team(s).
    </p>
    <p>
      Once you enter the site click on the “Sign in with Google” tab. Once you
      are in you can add multiple teams if you wish, each Team will require the
      entry fee amount.
    </p>
    <p>
      The site will have every NFL team that’s in the playoffs & every player
      that is eligible to play in the playoffs. You’ll be able to scroll through
      each one of them to make your selections for your team. You can search for
      players also in the search menu to expedite your search. There is an add
      or remove tab if you have your team entered and want to make changes to
      your team. The league will be locked 30 mins before the 1st playoff game
      kickoff time.
    </p>
    <p>
      All the stats are taken from NFL API Data The site point scoring system is
      in the settings tab. You can only select 1 player from each team & 1 team
      defense counts as one of the 14 player selections you can make.
    </p>
    <p></p>
    <p>
      The site will update during the game for your team’s players points along
      with the other teams & league standings. You might have to refresh your
      browser occasionally & let the site refresh for updates after scores.
      Also, the leaderboard will continue to update throughout the day & weeks
      of the playoffs.
    </p>
    <p>
      Feel free to email me if you have trouble getting into the site. Also,
      there will be a link on the front page of the site or once you are into
      your league at the top where there are 3 dots that you can logout there &
      there is a help tab link to email me there also.
    </p>
    <p>
      The entry fee will be $55. $50 from each team will be used towards the
      pot, for the top 4 team payouts. Once I get my admin cost of $200 covered
      for the site set up fee I will add that extra money towards a 5th place
      team payout. Obviously the payouts will get higher and higher by the more
      entries that we get. Please feel free to send out the site & my Venmo link
      to anyone that wants to enter the contest. If anybody needs to pay through
      a different pay app, just send me a text or an email. Once the contest is
      locked Saturday 30 mins before the 1st game kickoff time I will figure out
      the payouts for the top 4 teams & 5th place team if applicable.
    </p>
    <p>
      You can pay your entry fee to my Venmo. Just put your team name on the
      Venmo so I can check off your team as being paid. Link below.
    </p>
    <p>https://venmo.com/code?user_id=1837080187502592468</p>

    <h1>League Rules</h1>
    <p>
      Enter your team name to join the league. If you have multiple teams, click
      the "Add Team" button at the top of the page and enter the team name. You
      can add up to one player (including defense and kicker) from each team in
      the NFL playoffs. Once the playoffs start, you cannot change your lineup.
      You won't be able to see your leaguemate's teams or super bowl guesses
      until rosters are locked-in. Guess the total score of the Super Bowl. At
      the end of the playoffs, if a tiebreaker is necessary, this will be
      determined by whoever gets the closest to the actual score without
      guessing higher than the actual score.
    </p>
    <h2>You select for building your team:</h2>
    <p>3 Qb’s</p>
    <p>4 Running backs</p>
    <p>4 Receivers</p>
    <p>1 Tight-end</p>
    <p>1 Team Defense</p>
    <p>1 Kicker</p>
    <p>
      To view scoring settings, join the league and click on the "Settings" tab
      above your roster.
    </p>
    <h2>Scoring Point System</h2>
    <p>
      Note that a point is only counted when a player reaches the threshold for
      a point; for example, a player rushing for 59 yards will only receive 5
      points, and not 5.9.
    </p>
    <h3>Passing</h3>
    <h4>Passing Yards per Point</h4>
    <p>25.00</p>
    <h4>Passing TDs</h4>
    <p>6.0</p>
    <h4>Interceptions Thrown</h4>
    <p>-1.0</p>
    <h4>Passing 2-Pt Conv</h4>
    <p>2.0</p>
    <h3>Rushing</h3>
    <h4>Rushing Yards per Point</h4>
    <p>10.00</p>
    <h4>Rushing TDs</h4>
    <p>6.0</p>
    <h4>Rushing 2-Pt Conv</h4>
    <p>2.0</p>
    <h3>Receiving</h3>
    <h4>Receiving Yards per Point</h4>
    <p>10.00</p>
    <h4>Receiving TDs</h4>
    <p>6.0</p>
    <h4>Receiving 2-Pt Conv</h4>
    <p>2.0</p>
    <h3>Other</h3>
    <h4>Fumbles Lost</h4>
    <p>-1.0</p>
    <h4>Unordinary TDs</h4>
    <p>6.0</p>
    <h4>Bonus for 50+ Yard Touchdown - Pass, Rec, or Rush</h4>
    <p>2.0</p>
    <h3>Defense</h3>
    <h4>Touchdown</h4>
    <p>6.0</p>
    <h4>Safeties</h4>
    <p>2.0</p>
    <h4>Interceptions</h4>
    <p>1.0</p>
    <h4>Sacks</h4>
    <p>1.0</p>
    <h4>Fumbles Recovered</h4>
    <p>1.0</p>
    <h4>Defensive Shutout</h4>
    <p>5.0</p>
    <h4>Points for Less than 100 Rushing Yards Against</h4>
    <p>3.0</p>
    <h4>Points for Less than 150 Receiving Yards Against</h4>
    <p>4.0</p>
    <h3>Kickers</h3>
    <h4>FG Less than 40 Yd</h4>
    <p>3.0</p>
    <h4>FG 40-49 Yd</h4>
    <p>4.0</p>
    <h4>FG 50+ Yd</h4>
    <p>5.0</p>
    <h4>Extra Point Made</h4>
    <p>1.0</p>
  </>
);

const PaymentText = () => (
  <>
    <h1 level={2}>League Fee Payment</h1>
    <p>Entry fees are $55. Please send it to the Venmo link below.</p>
    <p>
      Also, please include your team name in the comment section so you can be
      checked off as paid.
    </p>
    <h3>
      <a href="https://venmo.com/code?user_id=1837080187502592468">
        Venmo Link
      </a>
    </h3>
  </>
);

const PopupModal = ({ modalVisible, setModalVisible }) => {
  return (
    <Modal
      title="The Rules"
      visible={modalVisible}
      onCancel={() => setModalVisible(null)}
      footer={
        <Button type="primary" onClick={() => setModalVisible(null)}>
          Got it!
        </Button>
      }
      className="custom-modal"
    >
      {modalVisible}
    </Modal>
  );
};

const HomePage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, setToken } = useContext(AuthContext);
  const [modalVisible, setModalVisible] = useState(false);

  const handleGoogleLogin = async (response) => {
    if (response && response.credential) {
      // Save the ID token in Auth Context and Local Storage
      setToken(response.credential, response.expiresIn);

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/google-signin`,
        response.credential,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );

      // Navigate to the next page after successful login
      navigate("/home-page");
    } else {
      message.error("Login failed");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        backgroundImage: "url('/football_field_background.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: "32px",
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card className="main-card">
          <h1 className="main-title">Man Cave Fantasy Football Playoffs</h1>
          <h2 className="main-card-text">Hosted by Mike McKellop</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "16px",
            }}
          >
            {isAuthenticated ? (
              <Button
                className="login-button"
                onClick={() => navigate("/home-page")}
              >
                View League
              </Button>
            ) : (
              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => message.error("Login failed")}
              />
            )}
          </div>
        </Card>
      </div>

      <div className="emblem-container">
        <div
          className="emblem clickable-emblem"
          onClick={() => setModalVisible(RulesText)}
        >
          <h2 className="emblem-title">Full Rules</h2>
          <p className="emblem-text">Click here to view the full rules</p>
        </div>

        <div
          className="emblem clickable-emblem"
          onClick={() => setModalVisible(PaymentText)}
        >
          <h2 className="emblem-title">League Fee Payment</h2>
          <p className="emblem-text">Click here to view payment info</p>
        </div>

        <div className="emblem">
          <h2 className="emblem-title">Need help?</h2>
          <p className="emblem-text">
            Contact Mike at{" "}
            <a className="emblem-link" href="mailto:sierrafch2121@gmail.com">
              sierrafch2121@gmail.com
            </a>
          </p>
        </div>
      </div>

      {/* <div>

        <Title level={2}>Full Rules</Title>
        <h3
          onClick={() => setModalVisible(true)}
          style={{ cursor: "pointer", color: "#1B92FF" }}
        >
          Click here for full rule details
        </h3>

        <Title level={2}>Need Help?</Title>
        <p>
          Contact Mike at{" "}
          <a href="mailto:sierrafch2121@gmail.com">sierrafch2121@gmail.com</a>
        </p>
        <Title level={2}>How Does It Work?</Title>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li
            style={{
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <RedditOutlined
              style={{
                fontSize: "2rem",
                paddingRight: "16px",
                color: "#001529",
              }}
            ></RedditOutlined>
            <div>
              <p style={{ fontSize: 20, marginBottom: 0 }}>
                Join with your team name
              </p>
              <p>If you already have a team, you'll be matched with it!</p>
            </div>
          </li>
          <li
            style={{
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <EditOutlined
              style={{
                fontSize: "2rem",
                paddingRight: "16px",
                color: "#001529",
              }}
            ></EditOutlined>
            <div>
              <p style={{ fontSize: 20, marginBottom: 0 }}>Add your players</p>
              <p>
                You can only have one player from each NFL team, including
                defense and kicker!
              </p>
            </div>
          </li>
          <li
            style={{
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BarChartOutlined
              style={{
                fontSize: "2rem",
                paddingRight: "16px",
                color: "#001529",
              }}
            ></BarChartOutlined>
            <div>
              <p style={{ fontSize: 20, marginBottom: 0 }}>
                Tiebreaker - Super Bowl Score
              </p>
              <p>
                Get as close as possible to the actual total score of the
                superbowl without going over!
              </p>
            </div>
          </li>
        </ul>
      </div>
 */}
      <PopupModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </div>
  );
};

export default HomePage;
export { PopupModal as RulesModal };
