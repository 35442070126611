// src/components/Header.js

import React, { useContext, useEffect, useState } from "react";
import { Layout, Menu, Button, Modal } from "antd";
import { AuthContext } from "../context/AuthContext"; // Update the path as necessary
import { useLocation, useNavigate } from "react-router-dom";

const { Header } = Layout;

const AppHeader = () => {
  const { isAuthenticated, setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState("/");

  useEffect(() => {
    setSelectedKeys(location.pathname);
  }, [location]);

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        className="hide-on-mobile"
        style={{ color: "white", fontSize: "20px", paddingRight: "24px" }}
      >
        Man Cave Fantasy Football Playoffs
      </div>
      {isAuthenticated && (
        <Menu
          theme="dark"
          mode="horizontal"
          style={{ flex: 1 }}
          selectedKeys={selectedKeys}
        >
          <Menu.Item
            key="/home-page"
            onClick={() => navigate("/home-page")}
          >
            Home Page
          </Menu.Item>

          <Menu.Item
            key="/view-leagues"
            onClick={() => navigate("/view-teams")}
          >
            Your Teams
          </Menu.Item>

          <Menu.Item
            key="/join-league"
            onClick={() => navigate("/join-league")}
          >
            Add Team
          </Menu.Item>
          {/* <Menu.Item
            key="/create-league"
            onClick={() => navigate("/create-league")}
          >
            Create League
          </Menu.Item> */}
          <Menu.Item
            type="primary"
            onClick={() => setModalVisible(true)}
          >
            Help
          </Menu.Item>
          <Menu.Item
            type="primary"
            onClick={handleLogout}
            style={{ marginLeft: "auto" }}
          >
            Logout
          </Menu.Item>
        </Menu>
      )}

      <Modal title="Help" open={modalVisible} onOk={() => setModalVisible(false)} onCancel={() => setModalVisible(false)}>
        <h4>Need help?</h4>
        <p>Contact Mike at <a href="mailto:sierrafch2121@gmail.com">sierrafch2121@gmail.com</a></p>
      </Modal>
    </Header>
  );
};

export default AppHeader;
