import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  PageHeader,
  Select,
  Spin,
  Table,
  message,
  Typography,
  Tag,
  Form,
  Checkbox,
  InputNumber,
  Space,
} from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import axiosInstance, {
  axiosInstancePlain,
  setAuthToken,
} from "../utils/axiosConfig";
import { LeagueFormItems } from "./CreateLeaguePage";
import { RulesModal } from "./HomePage";
import {
  getPayout,
  parseTeamData,
  parseDefLineupData,
  parseKickerLineupData,
  parseLineupData,
  getStatCols,
  getKickerStatCols,
  getDefStatCols,
  formatDecimal,
  convertUtcToUserTimezone,
} from "../utils/LeaguePageUtils.js";
const { Search } = Input;
const { Title } = Typography;

const LandingPage = () => {
  const [loadingPlayers, setLoadingPlayers] = useState(true);
  const [loadingLeague, setLoadingLeague] = useState(true);

  const [teamData, setTeamData] = useState([]);
  const [leagueData, setLeagueData] = useState([]);
  const [userId, setUserId] = useState([]);

  const [rosteredPlayers, setRosteredPlayers] = useState([]);

  const [teamSearchValue, setTeamSearchValue] = useState("");
  const [filteredTeamData, setFilteredTeamData] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedView, setSelectedView] = useState("standings");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [curWeek, setCurWeek] = useState(null);

  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      fetchData();
      fetchLeagueData();
    }
  }, [token]);

  const fetchData = async () => {
    setLoadingPlayers(true);

    try {
      const rosteredPlayersResponse = await axiosInstance.get(
        `rostered-players/${process.env.REACT_APP_LEAGUE_ID}`
      );
      setRosteredPlayers(rosteredPlayersResponse.data);
      setLoadingPlayers(false);
    } catch (error) {
      // navigate("/join-league");
      // message.error(`Failed to load team: ${error.response.data.detail}`);
    }
  };

  const fetchLeagueData = async () => {
    setLoadingLeague(true);
    try {
      const leagueResponse = await axiosInstance.get(
        `get-league-total-info/${process.env.REACT_APP_LEAGUE_ID}`
      );
      setLeagueData(leagueResponse.data.league);
      setTeamData(parseTeamData(leagueResponse.data.teams));
      setUserId(leagueResponse.data.userId);
    } catch (error) {
      // message.error(`Failed to load team: ${error.response.data.detail}`);
    }

    setLoadingLeague(false);
  };

  const showTeamModal = (team) => {
    setSelectedTeam(team);
    setIsModalVisible(true);
  };

  const leagueCols = useMemo(() => {
    const columns = [
      {
        title: "Team Name",
        dataIndex: "teamName",
        key: "teamName",
      },
      {
        title: "Rank",
        dataIndex: "rank",
        key: "rank",
      },
      {
        title: "Payout",
        key: "payout",
        render: (_, record) => getPayout(record.rank),
      },
      {
        title: "Total Points",
        dataIndex: "score",
        key: "score",
        render: (score) => formatDecimal(score),
      },
      {
        title: "Super Bowl Total",
        dataIndex: "superBowlTotal",
        key: "superBowlTotal",
      },
    ];

    columns.push({
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => showTeamModal(record)}>View Team</Button>
      ),
    });

    return columns;
  }, [teamData]);

  useEffect(() => {
    if (teamSearchValue) {
      const escapedSearchValue = teamSearchValue.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const regex = new RegExp(escapedSearchValue, "i"); // 'i' for case-insensitive
      setFilteredTeamData(teamData.filter((team) => regex.test(team.teamName)));
    } else {
      setFilteredTeamData(teamData);
    }
  }, [teamSearchValue]);

  useEffect(() => {
    setFilteredTeamData(teamData);
  }, [teamData]);

  useEffect(() => {
    if (selectedTeam) {
      setCurWeek(
        Object.keys(selectedTeam.lineups)[
          Object.keys(selectedTeam.lineups).length - 1
        ].toString()
      );
    }
  }, [selectedTeam]);

  const gameCols = [
    {
      title: "Opponent",
      key: "opponent",
      render: (_, record) =>
        record.opponent
          ? `${
              record.opponent === "BYE" || record.opponent === "--"
                ? ""
                : record.isHome
                ? "vs "
                : "@ "
            }${record.opponent}`
          : null,
    },
    {
      title: "Gametime",
      key: "startTime",
      render: (_, record) =>
        record.startTimestamp > 0
          ? convertUtcToUserTimezone(record.startTimestamp)
          : null,
    },
  ];

  const rosteredPlayerCols = [
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: "40px",
    },
    {
      title: "Player",
      key: "player",
      render: (_, record) => (
        <div
          style={{
            display: record.image ? "flex" : "none",
            gap: "16px",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <img
            src={record.image}
            alt="Player"
            // className="hide-on-mobile"
            style={{ width: "40px", height: "auto" }} // Adjust size as needed
          />
          {record.name}
        </div>
      ),
    },
    {
      title: "Teams Rostered",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Points",
      dataIndex: "score",
      key: "score",
      render: (score) => formatDecimal(score),
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
    },
  ];

  const playerCols = [
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: "40px",
    },
    {
      title: "Player",
      key: "player",
      render: (_, record) => (
        <div
          style={{
            display: record.image ? "flex" : "none",
            gap: "16px",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <img
            src={record.image}
            alt="Player"
            // className="hide-on-mobile"
            style={{ width: "40px", height: "auto" }} // Adjust size as needed
          />
          {record.name}
        </div>
      ),
    },
    {
      title: "Points",
      dataIndex: "score",
      key: "score",
      render: (score) => formatDecimal(score),
    },
    {
      title: "Teams Rostered",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
    },
    ...gameCols,
    // Add other rel
  ];

  const playersWithStatsCols = [...playerCols, ...getStatCols(false)];
  const kickerWithStatsCols = [...playerCols, ...getKickerStatCols(false)];

  const defenseCols = [
    {
      title: "Position",
      key: "position-def",
      width: "40px",
      render: () => "DEF",
    },
    {
      title: "Team",
      key: "team",
      render: (_, record) => (
        <div
          style={{
            display: record.logo ? "flex" : "none",
            gap: "16px",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <img
            src={record.logo}
            alt="Logo"
            // className="hide-on-mobile"
            style={{ width: "40px", height: "auto" }} // Adjust size as needed
          />
          {record.name}
        </div>
      ),
    },
    {
      title: "Points",
      dataIndex: "score",
      key: "score",
      render: (score) => formatDecimal(score),
    },
    {
      title: "Teams Rostered",
      dataIndex: "count",
      key: "count",
    },
    ...gameCols,
    // Add other rel
  ];

  const defenseWithStatsCols = useMemo(
    () => [
      ...defenseCols,
      ...getDefStatCols(
        leagueData?.scoringSettings?.rush_yds_against_bonus_threshold,
        leagueData?.scoringSettings?.rec_yds_against_bonus_threshold,
        false
      ),
    ],
    [leagueData]
  );

  return (
    <div>
      {leagueData.name ? (
        <>
          <PageHeader
            title={`${leagueData.name}`}
            style={{ marginBottom: "20px" }}
          />
          <div className="view-league-buttons">
            <Button.Group>
              <Button
                type={selectedView === "standings" ? "primary" : "default"}
                onClick={() => setSelectedView("standings")}
                size="large"
              >
                League Standings
              </Button>
              <Button
                type={selectedView === "players" ? "primary" : "default"}
                onClick={() => setSelectedView("players")}
                size="large"
              >
                Rostered Players
              </Button>
            </Button.Group>
          </div>
          <Button
            onClick={() => {
              fetchData();
              fetchLeagueData();
            }}
            style={{marginRight: "16px"}}
          >
            Refresh Stats
          </Button>
          {selectedView === "standings" &&
            (loadingLeague ? (
              <Spin size="large" />
            ) : (
              <>
                <Title level={2}>League Standings</Title>
                <Search
                  placeholder="Search for teams"
                  onChange={(e) => setTeamSearchValue(e.target.value)}
                  style={{ marginBottom: 20, maxWidth: 400 }}
                />

                <Table
                  rowClassName={(record) =>
                    record.userId === userId ? "table-row-highlight" : ""
                  }
                  dataSource={filteredTeamData}
                  columns={leagueCols}
                  rowKey="id"
                  className="overflow-table"
                  pagination={false}
                />
              </>
            ))}
          {selectedView === "players" &&
            rosteredPlayers &&
            (loadingPlayers ? (
              <Spin size="large" />
            ) : (
              <>
                <Title level={2}>Rostered Players</Title>
                <Table
                  rowClassName={(record) =>
                    record.eliminated ? "eliminated-player-row" : ""
                  }
                  dataSource={rosteredPlayers}
                  columns={rosteredPlayerCols}
                  pagination={false}
                  className="overflow-table"
                />
              </>
            ))}
        </>
      ) : (
        <Spin size="large" />
      )}

      {selectedTeam !== null ? (
        <Modal
          title={selectedTeam.teamName}
          visible={isModalVisible}
          width={700} // Adjust the width as needed
          onCancel={() => setIsModalVisible(false)}
          footer={
            <Button onClick={() => setIsModalVisible(false)}>Close</Button>
          }
        >
          <h4 level={4}>Select Week</h4>
          <Select
            defaultValue={"Total"}
            style={{ width: 120, marginBottom: 20 }}
            onChange={(value) => setSelectedWeek(value)}
          >
            {Object.keys(selectedTeam.lineups).map((week) => (
              <Select.Option key={week} value={week}>
                {week === "Total" ? "Total" : `Week ${week}`}
              </Select.Option>
            ))}
          </Select>

          <Table
            rowClassName={(record) =>
              record.eliminated ? "eliminated-player-row" : ""
            }
            dataSource={parseLineupData(
              selectedTeam.lineups[selectedWeek || curWeek],
              leagueData.rosterSettings
            )}
            columns={playersWithStatsCols}
            pagination={false}
            className="overflow-table"
          />
          <Table
            rowClassName={(record) =>
              record.eliminated ? "eliminated-player-row" : ""
            }
            dataSource={parseDefLineupData(
              selectedTeam.defenses[selectedWeek || curWeek],
              leagueData.rosterSettings
            )}
            columns={defenseWithStatsCols}
            pagination={false}
            className="overflow-table"
          />
          <Table
            rowClassName={(record) =>
              record.eliminated ? "eliminated-player-row" : ""
            }
            dataSource={parseKickerLineupData(
              selectedTeam.lineups[selectedWeek || curWeek],
              leagueData.rosterSettings
            )}
            columns={kickerWithStatsCols}
            pagination={false}
            className="overflow-table"
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default LandingPage;
